import Image from 'next/image';
import React from 'react';
import classNames from 'classnames';

export interface OurClientsProps {
  title: string;
  description: string;
  description_mobile: string;
  label: string;
  tabs: [];
}
export default function OurClients({
  title,
  description,
  label,
  tabs,
  description_mobile,
}: OurClientsProps) {
  return (
    <div className='land-container lg:py-12 md:py-10 py-8'>
      <h2 className='text-main-black lg:mb-2 mb-4'>{title}</h2>
      <p className='lg:block hidden text-main-black lg:mb-10 mb:mb-8 mb-6 lg:max-w-[773px] m-auto text-center text-base'>
        {description}
      </p>
      <p className='lg:hidden block text-main-black lg:mb-16 mb:mb-8 mb-6 lg:max-w-[773px] m-auto text-center text-base'>
        {description_mobile}
      </p>
      <h3 className='title text-main-black text-center lg:mb-10 mb-8'>
        {label}
      </h3>
      <div className='grid lg:grid-cols-3 grid-cols-2 gap-x-[2px] gap-y-2'>
        {tabs.map((i: any, index: number) => (
          <div
            className={classNames(
              `lg:odd:bg-main-tab nth-child-tab flex justify-center items-center w-full sm:min-h-[112px] min-h-[72px]`,
              index === tabs.length - 1 ? '' : {}
            )}
            key={i._uid}
          >
            <Image
              src={i.logo + '/m/'}
              width={100}
              height={100}
              alt={'icon'}
              className={'object-contain w-max box-border px-5'}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
