import Image from "next/image";
import React from "react";
import ApplyNow from "@/components/Buttons/ApplyNow";

export interface HeadProps {
  title: string;
  subtitle: string;
  head_description: string;
  head_description_mobile: string;
  head_image: string;
  applyNowText: string;
}
export default function HeadSection({
  title,
  subtitle,
  head_description,
  head_description_mobile,
  applyNowText,
  head_image,
}: HeadProps) {
  return (
    <div className="relative flex md:flex-row flex-col max-w-[1440px] m-auto bg-main-blue head-before items-stretch md:py-0 py-10 lg:px-0 md:px-11 px-4">
      <div className="flex flex-col md:py-[72px] lg:pl-[72px] w-full  head md:pr-6 max-w-[768px] box-content">
        <h1 className="mb-4 md:text-left text-center">{title}</h1>
        <h2 className="md:mb-10 mb-6 md:text-left text-center mb:font-bold font-light">
          {subtitle}
        </h2>
        <p className="mb-6 md:text-left text-center max-w-[636px] md:ml-0 m-auto md:block hidden">
          {head_description}
        </p>
        <p className="mb-6 md:text-left text-center max-w-[636px] md:ml-0 m-auto md:hidden block">
          {head_description_mobile}
        </p>
        <div className="md:block hidden">
          <ApplyNow applyNowText={applyNowText} className={"w-[311px]"} />
        </div>
      </div>
      <div className="md:w-[53%] w-full h-auto box-border">
        <div className="img-after rounded-2xl max-w-[526px] overflow-hidden h-full md:aspect-auto  aspect-[16/12] m-auto">
          <Image
            src={head_image + "/m/"}
            width={527}
            height={633}
            className="object-cover object-head w-full h-full"
            alt="HeadSection image"
            priority
          />
        </div>
        <div className="md:hidden flex justify-center mt-6">
          <ApplyNow
            applyNowText={applyNowText}
            className={"md:w-[311px] max-w-[344px] w-full"}
          />
        </div>
      </div>
    </div>
  );
}
