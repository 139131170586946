import React from "react";
import Image from "next/image";
import illustration from "@/images/JoinOurTeam/illustration-picture.png";
import ApplyNow from "@/components/Buttons/ApplyNow";
import { render } from "storyblok-rich-text-react-renderer";

export interface JoinOurTeamProps {
  title: string;
  content: any;
  image: string;
  applyNowText: string;
}
export default function JoinOurTeam({
  title,
  content,
  image,
  applyNowText,
}: JoinOurTeamProps) {
  return (
    <div className="lg:py-12 md:py-10 py-8 bg-main-blue">
      <div className="land-container flex md:flex-row flex-col items-center justify-between">
        <div className="lg:w-[496px] md:w-[388px] lg:pr-20">
          <h3 className="lg:mb-10 md:mb-6 mb-4 text-white md:text-left text-center">
            {title}
          </h3>
          <div className="text-content-white">{render(content)}</div>
          <div className="flex md:justify-start justify-center lg:mt-10 md:mt-6 mt-4">
            <ApplyNow
              applyNowText={applyNowText}
              className={"max-w-[380px] w-full text-xl"}
            />
          </div>
        </div>
        <div className="md:w-1/2 w-full md:mt-0 mt-8">
          <Image
            src={image + "/m/"}
            width={100}
            height={100}
            alt={"illustration"}
            className={"object-contain w-full"}
          />
        </div>
      </div>
    </div>
  );
}
