export interface OtherCompaniesProps {
  title: string;
  tabs: [];
}
export default function OtherCompanies({ title, tabs }: OtherCompaniesProps) {
  return (
    <div className="land-container lg:py-12 md:py-10 pt-4 pb-8 flex flex-col">
      <h3 className="text-center text-main-black mt-0 lg:mb-10 md:mb-8 mb-6 m-auto md:text-[28px] text-2xl">
        {title}
      </h3>
      <div className="sm:flex grid grid-cols-2 flex-wrap items-center lg:gap-6 gap-2 w-full justify-center">
        {tabs.map((i: any) => (
          <div
            key={i._uid}
            className="sm:bg-main-tab sm:min-h-[72px] sm:w-calc flex flex-tab items-center sm:justify-center sm:p-2 box-border"
          >
            <div className="dot"></div>
            <p className="text-base text-main-black text-center">{i.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
