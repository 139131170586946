import Image from "next/image";
import React from "react";
export interface SecurityTeamProps {
  title: string;
  subtitle: string;
  tabs: [];
}
export default function SecurityTeam({
  title,
  subtitle,
  tabs,
}: SecurityTeamProps) {
  return (
    <div className="lg:py-12 md:py-10 py-8 land-container">
      <p className="mb-2 text-main-grey text-center">{subtitle}</p>
      <h2 className="text-main-black lg:mb-10 mb-6">{title}</h2>
      <div className="grid md:grid-cols-3 gap-4">
        {tabs.map((i: any) => (
          <div
            className="rounded-2xl bg-main-tab lg:pt-8 lg:px-8 px-4 py-4 md:min-h-auto box-border w-full"
            key={i._uid}
          >
            <div className="flex flex-row items-center">
              <div className="h-10 w-10 mb-4">
                <Image
                  src={i.icon}
                  width={100}
                  height={100}
                  alt={"icon"}
                  className={"object-contain"}
                />
              </div>
              <h4 className="text-main-black mb-4 ml-4">{i.title}</h4>
            </div>
            <p className="lg:block hidden text-main-grey text-base">
              {i.description}
            </p>
            <p className="lg:hidden block text-main-grey text-base">
              {i.description_mobile}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
