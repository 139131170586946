import { NextSeo } from "next-seo";
import LandingHeader from "@/components/Header/LandingHeader";
import Head from "@/components/Landing/HeadSection";
import Services from "@/components/Landing/Services";
import Guard from "@/components/Landing/Guard";
import SecurityTeam from "@/components/Landing/SecurityTeam";
import Training from "@/components/Landing/Training";
import OurClients from "@/components/Landing/OurClients";
import JoinOurTeam from "@/components/Landing/JoinOurTeam";
import Footer from "@/components/Footer/Footer";
import axios from "axios";
import * as process from "process";
import { useQuery } from "react-query";
import { queryKeys } from "@/queries/queryKeys";
import React from "react";
import Image from "next/image";
import OtherCompanies from "@/components/Landing/OtherCompanies";

export default function Home() {
  const url = `https://api-us.storyblok.com/v2/cdn/stories/page/index?token=${process.env.NEXT_PUBLIC_STORYBLOK_API_KEY}&version=published`;
  const { data, isLoading } = useQuery(queryKeys.homepage, () =>
    axios.get(url)
  );
  const story = data?.data?.story?.content;

  const applyNowOptions = `https://api-us.storyblok.com/v2/cdn/stories/apply-now-options?token=${process.env.NEXT_PUBLIC_STORYBLOK_API_KEY}&version=published`;
  const { data: applyNowOptionsData, isLoading: isLoadingApplyNow } = useQuery(
    queryKeys.applyNowOptions,
    () => axios.get(applyNowOptions)
  );

  const applyNow = applyNowOptionsData?.data?.story?.content;

  return (
    <>
      <NextSeo
        title="Nitro security"
        openGraph={{
          title: "Nitro security",
          description:
            "Nitro Security operations are based on current and proven methods and techniques that have been developed by law enforcement departments and counter-terrorism agencies to counter known and anticipated threats. We constantly review our procedures to ensure our training incorporates scenarios that ensures the highest quality security product.",
        }}
      />
      {!isLoading && !isLoadingApplyNow && (
        <div className="landing relative overflow-hidden">
          <LandingHeader applyNowText={applyNow.apply_now_label} />
          <Head
            title={story.head_title}
            subtitle={story.head_subtitle}
            head_description={story.head_description}
            head_description_mobile={story.head_description_mobile}
            applyNowText={applyNow.apply_now_label}
            head_image={story.head_image}
          />
          <Services
            title={story.services_title}
            description={story.services_description}
            tabs={story.services_tabs_blocks}
            applyNowText={applyNow.apply_now_label}
          />
          <Guard
            title={story.guard_protect_secure_title}
            tabs={story.guard_protect_secure_blocks}
          />
          <SecurityTeam
            title={story.security_team_title}
            subtitle={story.security_team_subtitle}
            tabs={story.security_team_blocks}
          />
          <Training
            title={story.training_title}
            subtitle={story.training_subtitle}
            tabs={story.training_blocks}
          />
          <div className="sm:block hidden w-full lg:aspect-[16/5] lg:max-h-[unset] max-h-[260px] overflow-hidden relative img-after">
            <Image
              src={story.big_image + "/m/"}
              width={100}
              height={100}
              alt={"big_img"}
              className={"w-full object-cover object-left-top"}
            />
          </div>
          <OurClients
            title={story.our_clients_title}
            description={story.our_clients_description}
            description_mobile={story.our_clients_description_mobile}
            label={story.our_clients_label}
            tabs={story.our_clients_block}
          />
          <OtherCompanies
            title={story.other_companies_title}
            tabs={story.other_companies_blocks}
          />
          <JoinOurTeam
            title={story.join_our_team_title}
            content={story.join_our_team_content}
            image={story.join_our_team_image}
            applyNowText={applyNow.apply_now_label}
          />
          <Footer applyNowText={applyNow.apply_now_label} />
        </div>
      )}
    </>
  );
}
