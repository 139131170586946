import Arrow from "@/images/svg/ServicesArrow.svg";
import Image from "next/image";
import React from "react";
import ApplyNow from "@/components/Buttons/ApplyNow";
export interface ServicesProps {
  title: string;
  description: string;
  tabs: [];
  applyNowText: string;
}
export default function Services({
  title,
  description,
  tabs,
  applyNowText,
}: ServicesProps) {
  return (
    <div
      className="lg:py-12 md:py-10 py-8 land-container md:px-8"
      id="services"
    >
      <h2 className="lg:mb-2 mb-5">{title}</h2>
      <p className="text-main-grey text-center lg:pb-14 md:pb-12 pb-6 m-auto lg:max-w-[500px]">
        {description}
      </p>
      <div className="grid md:grid-cols-4 grid-cols-1 gap-x-4 md:gap-y-10 gap-y-4">
        {tabs.map((i: any) => (
          <div className="services-item" key={i._uid}>
            <div className="arrow">
              <Image src={Arrow} alt="arrow" />
            </div>
            <h3>{i.title}</h3>
          </div>
        ))}
      </div>
      <div className="md:mt-8 mt-6 flex items-center justify-center">
        <ApplyNow
          applyNowText={applyNowText}
          className={"md:w-[311px] w-full"}
        />
      </div>
    </div>
  );
}
