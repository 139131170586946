import React from "react";
import Image from "next/image";
import staffingSVG from "@/images/Guard/Staffing.svg";
import ImageStaffing from "@/images/Guard/ImageStaffing.png";
import classNames from "classnames";
export interface GuardProps {
  title: string;
  tabs: [];
}
export default function Guard({ title, tabs }: GuardProps) {
  return (
    <div className="bg-main-blue">
      <div className="lg:py-12 md:py-10 py-8 land-container">
        <h2 className="text-white lg:mb-10 md:mb-8 mb-6 text-center">
          {title}
        </h2>
        {tabs.map((i: any, index) => (
          <div
            key={i._uid}
            className={classNames(
              "flex md:flex-row flex-col max-w-[1440px] w-full items-center justify-center md:mb-10 mb-8 last:mb-0  m-auto",
              (index + 1) % 2 ? " md:flex-row-reverse" : ""
            )}
          >
            <div
              className={classNames(
                "flex flex-col md:w-[31%] w-full",
                (index + 1) % 2 ? "md:ml-6 ml-0" : "md:mr-6 mr-0"
              )}
            >
              <div className="flex flex-row items-center md:mb-6 md:mb-4 mb-2">
                <Image
                  src={i.icon}
                  alt={"staffingSVG"}
                  width={40}
                  height={40}
                  className={"object-contain w-full h-10 w-10"}
                />
                <h4 className="text-white ml-4">{i.title}</h4>
              </div>
              <p className="md:block hidden max-w-[708px] text-base">
                {i.description}
              </p>
              <p className="md:hidden block max-w-[708px]">
                {i.description_mobile}
              </p>
            </div>
            <div
              className={classNames(
                "w-full lg:max-w-[636px] md:max-w-[456px] relative w-full"
              )}
            >
              <div className="img-after md:mt-0 mt-6">
                <Image
                  src={i.image + "/m/"}
                  width={636}
                  height={300}
                  alt={"ImageStaffing"}
                  className={
                    "lg:bg-white object-cover rounded-xl overflow-hidden w-full md:h-[300px] sm:h-[320px] h-[220px]"
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
