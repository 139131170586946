import React from "react";
import Image from "next/image";
export interface TrainingProps {
  title: string;
  subtitle: string;
  tabs: [];
}
export default function Training({ title, subtitle, tabs }: TrainingProps) {
  return (
    <div className="lg:py-12 md:py-10 py-8 bg-main-blue">
      <div className="land-container">
        <p className="mb-2 text-white text-center">{subtitle}</p>
        <h2 className="text-white lg:mb-10 md:mb-8 mb-6">{title}</h2>
        <div className="grid md:grid-cols-4 sm:grid-cols-2 lg:gap-10 gap-4">
          {tabs.map((i: any) => (
            <div className="flex sm:items-start items-center" key={i._uid}>
              <div className="mr-4">
                <Image
                  src={i.icon}
                  width={40}
                  height={40}
                  alt={"icon"}
                  className={"object-contain md:h-10 md:w-10 h-8 w-8"}
                />
              </div>
              <p className="text-white w-full md:text-xl">{i.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
